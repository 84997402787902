export default function throttle(fun, time) {
    let timeout = undefined;
    return (...args) => {
        if (timeout)
            clearTimeout(timeout);
        timeout = setTimeout(() => {
            fun(...args);
            timeout = undefined;
        }, time);
    };
}
