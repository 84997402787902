export async function sleep(time) {
    return new Promise((x) => {
        setTimeout(x, time);
    });
}
export async function alwaysTry(a) {
    while (true) {
        const k = a();
        if (k)
            return k;
        await sleep(300);
    }
}
export function getTimelines() {
    if (!window.timelines)
        window.timelines = document.querySelector('timelines');
    return window.timelines;
}
export function generateExportVarFunction(baseElement) {
    return (name) => getComputedStyle(baseElement).getPropertyValue('--' + name.replace('$', ''));
}
export function pxify(px) {
    return Number(px.replace('px', ''));
}
