export function categorizeSounds(sounds) {
    const starList = {};
    for (const i of sounds) {
        const id = encodeURIComponent(i.title) + '|' + encodeURIComponent(i.artist);
        if (!starList[id])
            starList[id] = [];
        starList[id].push(i);
    }
    return starList;
}
